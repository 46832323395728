import React, { useEffect, useState, useContext} from 'react';

import { HeaderContext } from './HeaderContext';
import ThemeWrapper, { EARTH } from '../factory/ThemeWrapper';

import Header from './Header';
import HeaderE from '../earth/HeaderE';
import PageLoaderAnimation from '../ui/PageLoaderAnimation';
import HeaderFactory from './HeaderFactory/HeaderFactory';
import { getSettingsAPI } from '../../../util/lib';
import { PAGE_TOP_PADDING, SITE_BODY } from '../util/pagePaddingHackDecorator';

let loaded = false;

const HeaderMaster = (props = {}) => {
	const headerContextData = useContext(HeaderContext),
		{ 
			pageLoading = false, 
			widget = {}, 
			page = '', 
			pageMeta = {}, 
			hidden = false, 
			isWebview = '', 
			load = () => {}, 
			siteLogo = '', 
			videoDetails = {}, 
			settingsData = {}, 
			canShowShoppingCartYN = false, 
			hideCount = 0, 
			hideHeader = () => {}, 
			hasScrollWidget = false
		} = headerContextData,
		[isHeaderV2, setIsHeaderV2] = useState(false),
		[settingsLoaded, setSettingsLoaded] = useState(false);
		
	useEffect(() => {
		getSettingsAPI().then((settings = {}) => {
			setSettingsLoaded(true);
			setIsHeaderV2(!(!settings.isHeaderV2));
		}).catch(() => {
			console.error("Unable to get Settings Data")
		});
	}, []);
	

		if (widget.layout === 3) {
			const siteBody = document.querySelector(`.${SITE_BODY}`);

			if (siteBody) {
				siteBody.classList.add(PAGE_TOP_PADDING);
			}
		}

		const theme = props.theme || '',
		HComp = (theme === EARTH) ? HeaderE : Header;
		if (!loaded) {
			loaded = true;
			load();
		}
		
		return (<>
		{settingsLoaded && <>
			{isHeaderV2 ?
				<>
					{widget && widget.type === 'NavBar' && !hidden && <HeaderFactory data={widget} activePage={page} pageMeta={pageMeta} hasScrollWidget={hasScrollWidget}/>}
					{pageLoading && <PageLoaderAnimation />}
				</>
				
				: <>
					{!isWebview && siteLogo && <HComp
						page={page}
						settingsData={settingsData}
						siteLogo={siteLogo}
						header={widget}
						pageMeta={pageMeta}
						videoDetails={videoDetails}
						canShowShoppingCartYN={canShowShoppingCartYN}
						hideCount={hideCount}
					/>}
					{pageLoading && <PageLoaderAnimation />}
				 </>
			}
		</>}
	</>);
}

export default ThemeWrapper(HeaderMaster);