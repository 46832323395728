import React, { useContext, useEffect, useState } from "react";
import HybridFooter from "../../hybrid/footer/HybridFooter";
import FooterJ from "../../jupiter/FooterJ";
import FooterE from "../../earth/FooterE";
import Footer from "../../Footer";
import { HeaderContext } from "../../header/HeaderContext";
import FooterStore from "../../hybrid/footer/FooterStore";

const FooterManager = (props = {}) => {
    const {
            theme,
            settingsData = {},
            termsConsentCheck,
            hasScrollWidgetFooter = false,
        } = props,
        { isFooterV2 = false } = settingsData,
        { headerLoaded, firstPageLoaded } = useContext(HeaderContext),
        [canRender, setCanRender] = useState(false),
        { hidden } = useContext(FooterStore);

    useEffect(() => {
        if (!canRender && firstPageLoaded && headerLoaded) {
            setCanRender(true);
        }
    }, [headerLoaded, firstPageLoaded]);

    const footerComponents = {
        2: FooterE,
        3: FooterJ,
        default: Footer,
    };

    const FooterComponent = isFooterV2
        ? HybridFooter
        : footerComponents[theme] || footerComponents["default"];

    return (
        <>
            {canRender ? (
                <FooterComponent
                    termsConsentCheck={termsConsentCheck}
                    settingsData={settingsData}
                    hidden={hidden}
                    hasScrollWidgetFooter={hasScrollWidgetFooter}
                />
            ) : null}
        </>
    );
};

export default FooterManager;
