import { RATIO_16x9, RATIO_1x1, RATIO_2x3, RATIO_3x4 } from "./imageUtils";
import { ALL_APP_SETTINGS, getLC } from "./lib";
// import { debugLog } from "./logUtils";

const XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
	XL = 'xl',
	XXL = 'xxl',
	CARD_SIZES = [XS,SM, MD, LG, XL, XXL],
	RATIOS = [RATIO_2x3, RATIO_3x4, RATIO_16x9, RATIO_1x1],
	SIZE_CSS_CLASS_MAP = {
		'sm': 'small',
		'md': 'medium',
		'lg': 'large',
		'xl': 'extra-large',
		'xxl': 'extra-extra-large',
		//deprecated
		'large': 'large',
		'regular': 'regular',
	},
	TITLE_ALIGNMENT_CLASS = {
		"left": "title-left",
		"center": "title-center",
		"right": "title-right"
	},
	TITLE_ALIGNMENT_CLASS_DFLT = TITLE_ALIGNMENT_CLASS['left'],
	TITLE_TEXT_SIZE_CLASS = {
		"small": "ttl-size-small",
		"normal": "ttl-size-normal",
		"large": "ttl-size-large",
		"sm": "ttl-size-small",
		"md": "ttl-size-normal",
		"lg": "ttl-size-large"
	},
	TITLE_TEXT_SIZE_CLASS_DFLT = TITLE_TEXT_SIZE_CLASS['normal'],
	CARD_SIZE_CSS_CLASS_MAP = {
		'xs': 'col-6 col-sm-2 col-md-2 col-lg-2 extra-small-card',
		'sm': 'col-6 col-sm-2 col-md-2 col-lg-2 small-card',
		'md': 'col-6 col-sm-4 col-md-4 col-lg-4 medium-card',
		'lg': 'col-6 col-sm-4 col-md-4 col-lg-4 large-card',
		'xl': 'extra-large-card',
		'xxl': 'extra-extra-large-card',
		'sm-vl': 'small-card',
		'md-vl': 'col-12 col-sm-12 col-md-12 col-lg-12 medium-card',
		'lg-vl': 'col-12 col-sm-12 col-md-12 col-lg-12 large-card',
		'xl-vl': 'extra-large-card',
		'xxl-vl': 'extra-extra-large-card'
	},
	MAXLINE_CSS_CLASS_MAP = {
		1: 'one-liner',
		2: 'two-liner',
		3: 'three-liner'
	},
	CARD_PER_ROW_MAP = {
		'2x3': {
			sm: 6,
			md: 5,
			lg: 3,
			xl: 2,
			xxl: 1
		},
		'1x1': {
			sm: 6,
			md: 5,
			lg: 3,
			xl: 2,
			xxl: 1
		},
		'3x4': {
			sm: 6,
			md: 5,
			lg: 3,
			xl: 2,
			xxl: 1
		},
		'16x9': {
			sm: 5,
			lg: 3,
			md: 4,
			xl: 2,
			xxl: 1,
			xs: 9,
		},
	},
	CARD_PER_ROW_SM_MAP = {
		'2x3': {
			sm: 3,
			md: 2,
			lg: 1,
			xl: 1,
			xxl: 1
		},
		'1x1': {
			sm: 3,
			md: 2,
			lg: 1,
			xl: 1,
			xxl: 1
		},
		'3x4': {
			sm: 3,
			md: 2,
			lg: 1,
			xl: 1,
			xxl: 1
		},
		'16x9': {
			sm: 3,
			md: 2,
			lg: 1,
			xl: 1,
			xxl: 1,
			xs:3,
		},
	},
	CARD_CONTENT_ALIGNMENT = {
		"top-left": "top-left",
		"top-center": "top-center",
		"top-right": "top-right",
		"middle-left": "middle-left",
		"middle-center": "middle-center",
		"middle-right": "middle-right",
		"bottom-left": "bottom-left",
		"bottom-center": "bottom-center",
		"bottom-right": "bottom-right",
	},
	GRADIENT_DIRECTION_MAP = {
		'bottom-top': 'bottom-top',
		'top-bottom': 'top-bottom',
		'left-right': 'left-right',
		'right-left': 'right-left',
		'full': 'full-gradient'
	},
	BUTTON_SIZE_MAP = {
		'sm': 'btn-sm',
		'md': 'btn-md', // TEUX DEUX ~ add an md buttons css .. for now md = lg
		'lg': 'btn-lg',
	},
	BORDER_RADIUS_MAP = {
		'sm': 'rounded-sm',
		'md': 'rounded-md',
		'lg': 'rounded-lg',
	};

export const getBorderFromAllAppSettings = () => {
	const allAppSettings = getLC(ALL_APP_SETTINGS) || {},
		{ web_settings: webSettings = {} } = allAppSettings,
		{ buttonBorderRadius = "md" } = webSettings;
		return getBorderRadiusClass(buttonBorderRadius);
}

export const getBorderRadiusClass = (size = LG) => {
	return BORDER_RADIUS_MAP[size] || '';
}

export const getButtonSizeClass = (size) => {
	return BUTTON_SIZE_MAP[size] || '';
};

export const parseSize = (size = MD) => {
	return CARD_SIZES.indexOf(size) >= 0 ? size : MD;
};

export const parseRatio = (ratio = RATIO_3x4) => {
	return RATIOS.indexOf(ratio) >= 0 ? ratio : RATIO_3x4
};

export const correctCardMetaSizeAndRatio = (meta = {}) => {
	const size = meta.card_size || '',
		ratio = meta.card_ratio || '';
	return {
		...meta,
		card_size: parseSize(size),
		card_ratio: parseRatio(ratio)
	};
};

export const correctTextMetaSizeAndMaxLine = (meta = {}) => {
	const size = meta.size || '',
		maxLine = meta.maxLine || 2;

	return {
		...meta,
		size: parseSize(size),
		maxLine
	};
};

export const getCardPerRow = (meta) => {


	if (!CARD_PER_ROW_MAP.hasOwnProperty(meta.card_ratio)) {
		return '';
	} else if (!CARD_PER_ROW_MAP[meta.card_ratio].hasOwnProperty(meta.card_size)) {
		return '';
	}
	return CARD_PER_ROW_MAP[meta.card_ratio][meta.card_size];
};

export const getCardPerRowSmallScreen = (meta) => {

	if (!CARD_PER_ROW_SM_MAP.hasOwnProperty(meta.card_ratio)) {
		return '';
	} else if (!CARD_PER_ROW_SM_MAP[meta.card_ratio].hasOwnProperty(meta.card_size)) {
		return '';
	}
	return CARD_PER_ROW_SM_MAP[meta.card_ratio][meta.card_size];
};

export const getMaxLineClass = (maxLine) => {
	if (!MAXLINE_CSS_CLASS_MAP.hasOwnProperty(maxLine)) {
		return '';
	}
	return maxLine ? MAXLINE_CSS_CLASS_MAP[maxLine] : '';
};

export const getSizeClass = (size) => {
	if (!SIZE_CSS_CLASS_MAP.hasOwnProperty(size)) {
		return '';
	}
	return size ? SIZE_CSS_CLASS_MAP[size] : '';
};

export const getCardSizeClass = (size) => {

	if (!CARD_SIZE_CSS_CLASS_MAP.hasOwnProperty(size)) {
		return '';
	}
	return size ? CARD_SIZE_CSS_CLASS_MAP[size] : '';
};

export const getTextStyleString = (meta = {}) => {
	const textSize = meta.textSize || meta.size || '';
	let styleString = '';

	if (meta.maxLine) {
		styleString = styleString + " " + getMaxLineClass(meta.maxLine);
	}
	if (textSize) {
		styleString = styleString + " " + getSizeClass(textSize);
	}

	return styleString;
}

export const getStyleString = (meta = {}, shouldIncludeRatio = false, isInVerticalListing = false, shouldIncludeMaxLine = true) => {
	let styleString = '';

	if (shouldIncludeMaxLine && meta.maxLine) {
		styleString = styleString + " " + getMaxLineClass(meta.maxLine);
	}
	if (meta.textSize || meta.size) {
		styleString = styleString + " " + getSizeClass(meta.textSize || meta.size);
	}
	if (meta.card_size) {
		styleString = styleString + " " + getCardSizeClass(meta.card_size + (isInVerticalListing ? '-vl' : ''));
	}
	if (shouldIncludeRatio && meta.card_ratio) {
		styleString = styleString + '-' + (meta.card_ratio.replace('x', '_'));
	}

	return styleString;
};

export const getFlexAlignment = (meta) => {
	const alignment = meta.alignment || '',
		separator = meta.separator || '';

	switch (alignment) {
		case "left":
			return " justify-content-start";
		case "center":
			return " justify-content-center";
		case "right":
			return " justify-content-end";
		case "sticktoend":
			return " justify-content-between";
		default:
			return separator ? " justify-content-start" : '';
	}
};

export const getTagCssClass = (type) => {
	switch (type) {
		case "tag-primary":
			return " tag-primary";
		case "tag-secondary":
			return " tag-secondary";
		case "callout-primary":
			return " callout-primary";
		case "callout-secondary":
			return " callout-secondary";
		default:
			return "";
	}
};

export const getTitleAlignment = (alignementMeta = TITLE_ALIGNMENT_CLASS_DFLT) => {
	return TITLE_ALIGNMENT_CLASS[alignementMeta] || TITLE_ALIGNMENT_CLASS_DFLT;
};

export const getTitleTextSize = (textSizeMeta = TITLE_TEXT_SIZE_CLASS_DFLT) => {
	return TITLE_TEXT_SIZE_CLASS[textSizeMeta] || TITLE_TEXT_SIZE_CLASS_DFLT;
};

export const getCardContentAlignment = (alignementMeta) => {
	if (alignementMeta) {
		return CARD_CONTENT_ALIGNMENT[alignementMeta];
	}
	return "";
};

export const getGradientDirectionClass = (gradientDirectionMeta) => {
	if (gradientDirectionMeta) {
		return GRADIENT_DIRECTION_MAP[gradientDirectionMeta];
	}
	return "";
};
