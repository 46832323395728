import React, { lazy, Suspense } from "react";
import Loadable from "@react-loadable/revised";

const LayoutOne = lazy(() => import("./LayoutOne")),
    LayoutTwo = lazy(() => import("./LayoutTwo")),
    LayoutThree = lazy(() => import("./LayoutThree")),
    LayoutFour = lazy(() => import("./LayoutFour")),
    LayoutFive = lazy(() => import("./LayoutFive"));

export const footerLayoutMap = {
    1: (props) => (
        <Suspense fallback={<></>}>
            <LayoutOne {...props} />
        </Suspense>
    ),
    2: (props) => (
        <Suspense fallback={<></>}>
            <LayoutTwo {...props} />
        </Suspense>
    ),
    3: (props) => (
        <Suspense fallback={<></>}>
            <LayoutThree {...props} />
        </Suspense>
    ),
    4: (props) => (
        <Suspense fallback={<></>}>
            <LayoutFour {...props} />
        </Suspense>
    ),
    5: (props) => (
        <Suspense fallback={<></>}>
            <LayoutFive {...props} />
        </Suspense>
    ),
};
