import { clearL, getL, setL } from "./lib";
import { debugLog } from "./logUtils";
const techKeys = {
    "google": "google_info",
}
//check based on env
export const isGDPREnabled = (tcData = {}) => {
    if (!tcData) {
        const tcDataL = getL("cmp-tcdata");
        return tcDataL.gdprApplies;
    }
    return tcData.gdprApplies;
}

//getTcData with all the content (purpose and data)
export const getTcData = (callback) => {
    const tcfapi = window.__tcfapi || '';

    if (tcfapi) {
        tcfapi('getTCData', 2, (tcData, success) => {
            if (success) {
                clearL("cmp-tcdata");
                setL('cmp-tcdata', tcData);
                if (callback) {
                    callback(tcData)
                }
            } else {
                debugLog('getTCData failed')
            }
        });
    }
}


export const isSocialLoginAllowed = (tcData = {}) => {
    debugLog('isSocialLoginAllowed', tcData);
    if (isGDPREnabled(tcData)) {
        debugLog('isSocialLoginAllowed:', "enabled");

        const { publisher = {}, purpose = {} } = tcData,
            { consents = {} } = publisher,
            { consents: purposeConsents = {} } = purpose;
        debugLog('isSocialLoginAllowed:', consents['1'], purposeConsents['1']);
        return consents['1'] && purposeConsents['1'];
    } else {
        return true;
    }
}


export const getOtherVendors = (callback) => {
    const tcfapi = window.__tcfapi || '';
    if (tcfapi) {
        tcfapi("getOtherVendorConsents", 2, (otherVendorConsents, success) => {
            if (success) {
                clearL("cmp-othervendors");
                setL('cmp-othervendors', otherVendorConsents);
                if (callback) {
                    callback(otherVendorConsents)
                }
            } else {
                console.error('Error: could not get otherVendorConsents')
            }
        });
    }
}

//specific to sibbo tools might change for other vendors
export const isOtherTechEnabledSibbo = (tech = "", otherVendors = false) => {
    if (!otherVendors) {
        otherVendors = getL("cmp-othervendors");
    }
    if (isGDPREnabled()) {
        if (tech && otherVendors) {
            return otherVendors[techKeys[tech]] === '1';
        } else {
            return false;
        }
    } else {
        return true;
    }
}