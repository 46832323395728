/**
 * NEED IMPROVEMENT
 * - HTML DESCRIPTION/MESSAGE
 * HARDCODED
 */

import React, { Component, useCallback, useState } from "react";

import "./../scss/PrivacyCheck.scss";
import {  getAPIData, getL, setL } from '../../util/lib';
import { TERMS_CONSENT_CHECK } from '../../util/universalConstans';
import { getBorderFromAllAppSettings } from '../../util/classMapperUtils';

const PrivacyCheck = ({
  widget = {},
  themeType = "LIGHT",
  dataTestid = "",
} = {}) => {
  // console.log("pdata:", widget);

  const { data = "" } = widget,
    { labels = {}, terms_link = {}, privacy_link = {} } = data,
    { agree_message = "", terms_label = "", privacy_label = "" } = labels,
    { path: termsLink = "/" } = terms_link,
    { path: privacyLink = "/" } = privacy_link,
    borderClass = getBorderFromAllAppSettings();

  const [active, setActive] = useState(true);

  const onAcceptClick = useCallback(() => {
    const did = getL("did"),
      data = widget.data || {},
      consentLinkObj = data.update_terms_consent_link || {},
      consentLink = consentLinkObj.url || "";

    if (consentLink) {
      getAPIData(consentLink, { did }, ({ type = "" } = {}) => {
        if (type === "Success") {
          setActive(false);
          setL(TERMS_CONSENT_CHECK, true);
        }
      });
    }
  }, []);


	return <div data-testid="privacy_check" className={`privacy_check theme-${themeType}`}  style={{ display: (active ? 'block' : 'none') }}>
		<div className="container-fluid">
			<div className="row" >
				<div className="col-11 col-sm-9 col-lg-10">
					<div className="label-hldr">
						{((agree_message || '').replace((terms_label), ' ')).replace('.', '')}<a href={termsLink}>{terms_label}</a>
						{'. '}
						{((labels.personal_data_message || '').replace((privacy_label), ' ')).replace('.', '')}<a href={privacyLink}>{privacy_label}.</a>
					</div>
				</div>
				<div className="col-2 col-sm-3 col-lg-2 text-right">
					<div>
						<button type="button" className={`btn vt-btn-primary btn-lg vt-btn vt-btn-lg ${borderClass}`} onClick={onAcceptClick} >Accept</button>
					</div>
				</div>
			</div>
		</div>
	</div>
}
export default PrivacyCheck;	