// sessionManager.js

// Constants
const SESSION_KEY = 'appSessionId',
	EXPIRY_KEY = 'appSessionExpiry',
	SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 hours

/**
 * Generates a unique session ID using timestamp and random string
 * @returns {string} A unique session identifier
 */
const generateSessionId = () => {
	const timestamp = Date.now().toString(36),
		randomStr = Math.random().toString(36).substring(2, 2 + 9);

	return `${timestamp}-${randomStr}`;
};



/**
 * Creates a new session with an ID and expiration time
 * @returns {string} The newly created session ID
 */
const createSession = () => {
	const sessionId = generateSessionId(),
		expiry = Date.now() + SESSION_DURATION;

	sessionStorage.setItem(SESSION_KEY, sessionId);
	sessionStorage.setItem(EXPIRY_KEY, expiry.toString());

	return sessionId;
};

/**
 * Checks if the current session is still valid based on expiration time
 * @returns {boolean} True if session exists and hasn't expired, false otherwise
 */
const isSessionValid = () => {
	const expiry = sessionStorage.getItem(EXPIRY_KEY);

	return expiry && Date.now() < parseInt(expiry);
};

/**
 * Gets the current session ID, creating a new one if none exists or if expired
 * @returns {string} The current session ID
 */
const getSessionId = () => {
	let sessionId = sessionStorage.getItem(SESSION_KEY);
	
	if (!sessionId || !isSessionValid()) {
		sessionId = createSession();
	}

	return sessionId;
};

/**
 * Checks if a valid session exists
 * @returns {boolean} True if a valid session exists, false otherwise
 */
const hasSession = () => {
	return !!sessionStorage.getItem(SESSION_KEY) && isSessionValid();
};

/**
 * Clears the current session and its expiration
 * @returns {void}
 */
const clearSession = () => {
	sessionStorage.removeItem(SESSION_KEY);
	sessionStorage.removeItem(EXPIRY_KEY);
};



/**
 * Session management utilities
 * @type {Object}
 * @property {function} getSessionId - Gets current session ID
 * @property {function} createSession - Creates new session
 * @property {function} hasSession - Checks if valid session exists
 * @property {function} clearSession - Clears current session
 * @property {function} isSessionValid - Checks session validity
 */
const sessionManager = {
	getSessionId,
	createSession,
	hasSession,
	clearSession,
	isSessionValid
};

export default sessionManager;