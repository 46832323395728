import { getS, setS } from "./lib";

const SCROLL_STORE = 'scroll_store',
	PAGE_INDEX_STORE = 'page_index_store',
	PATHNAME_STORE = 'pathname_store';

export const getScrollRestorationStore = () => {
	const stack = [],
		setPathname = (pathname = 0) => {
			setS(PATHNAME_STORE, pathname);
		},
		getPathname = () => {
			const pathname = getS(PATHNAME_STORE) || '';
			
			return pathname;

		},
		setPageIndex = (pageIndex = 0) => {
			setS(PAGE_INDEX_STORE, pageIndex);
		},
		getPageIndex = () => {
			const pageIndexString = getS(PAGE_INDEX_STORE) || 0;
			
			return parseInt(pageIndexString) || 0;

		},
		addPageScrollPosition = (pathname = '', pageIndex = 0, scrollPositionY = 0) => {
			let scrollStoreGetObject = {},
				scrollStoreSetObject,
				scrollStoreSetRaw,
				scrollStoreInstanceObject;

			const scrollStoreRaw = getS(SCROLL_STORE) || '{}';

			try {
				scrollStoreGetObject = JSON.parse(scrollStoreRaw) || {};
			} catch (error) {
				console.log(error);
				scrollStoreGetObject = {}
			}

			scrollStoreInstanceObject = scrollStoreGetObject[pathname] || {};
			scrollStoreInstanceObject[pageIndex] = scrollPositionY;

			//console.log('**&&**', scrollStoreInstanceObject);
			//console.log('**&&**', scrollStoreGetObject);

			scrollStoreSetObject = {...scrollStoreGetObject};
			scrollStoreSetObject[pathname] = scrollStoreInstanceObject
			scrollStoreSetRaw = JSON.stringify(scrollStoreSetObject);
			setS(SCROLL_STORE, scrollStoreSetRaw);
		},
		getPageScrollPosition = (pathname = '', pageIndex = 0) => {
			let scrollStoreGetObject = {},
				scrollStoreInstanceObject,
				pageIndexArray,
				currentKeyIndex = -1,
				scrollVal = 0
				


			const scrollStoreRaw = getS(SCROLL_STORE) || '{}';

			try {
				scrollStoreGetObject = JSON.parse(scrollStoreRaw) || {};
			} catch (error) {
				console.log(error);
				scrollStoreGetObject = {}
			}

			scrollStoreInstanceObject = scrollStoreGetObject[pathname] || {};
			pageIndexArray = Object.keys(scrollStoreInstanceObject);
			currentKeyIndex = pageIndexArray.indexOf(String(pageIndex));

			if (currentKeyIndex > 0) {
				const previousKeyIndex = currentKeyIndex - 1;

				scrollVal = scrollStoreInstanceObject[pageIndexArray[previousKeyIndex]] || 0;
			} 

			return scrollVal;
			// console.log('**&&**', pageIndex, pageIndexArray, );

		};
		
	return {
		addPageScrollPosition,
		getPageScrollPosition,
		setPageIndex,
		getPageIndex,
		setPathname,
		getPathname
	};
} 