import React, { useState } from 'react';
import { useEffect } from 'react';
import { getScrollRestorationStore } from '../../../util/scrollRestorationStore';
import { withRouter } from 'react-router-dom';

const PUSH = 'PUSH',
	POP = 'POP';

const ScrollRestoration = ({history = {}, widgetsData = ''} = {}) => {
	const { action = '', location = {} } = history,
		{ pathname = '' } = location,
		[ scrollRestorationStore ] = useState(getScrollRestorationStore()),
		[ pageCount, setPageCount ] = useState(scrollRestorationStore.getPageIndex() || 0),
		[ canRestoreScroll, setCanRestoreScroll ] = useState(false),
		[ pageRenderCount, setPageRenderCount ] = useState(0);

	useEffect(() => {
		// This effect adds an event listener for the scroll event when the pageCount is set.
		// It stores the current scroll position in the scrollRestorationStore.
		
		if (pageCount && window && document) {
			const onScroll = () => {
				const scrollPositionY = document.documentElement.scrollTop || document.body.scrollTop || 0;
	
				scrollRestorationStore.addPageScrollPosition(pathname, pageCount, scrollPositionY);
			};
			window.addEventListener("scroll", onScroll);
			onScroll();
			
			return () => {
				window.removeEventListener("scroll", onScroll);
			}
		}
	}, [pageCount]); 

	useEffect(() => {
		// This effect updates the pageCount and pathname in the scrollRestorationStore
		// whenever the pathname changes.
		// This effect also sets the canRestoreScroll state to true when the action is POP and false when it is not.
		// Furthermore, it increments the pageRenderCount state when the pathname changes.
		
		const storePathname = scrollRestorationStore.getPathname() || '';

		setCanRestoreScroll(action === POP);

		if (storePathname !== pathname) {
			setPageRenderCount(pageRenderCount + 1);
			scrollRestorationStore.setPageIndex(pageCount + 1);
			setPageCount(scrollRestorationStore.getPageIndex());
		}

		scrollRestorationStore.setPathname(pathname);
	}, [pathname]);

	useEffect(() => {
		// This effect restores the scroll position when the widgetsData is updated
		// and the action is POP (indicating a back navigation).

		console.log('**&&**', canRestoreScroll);
		
		if (window && widgetsData && Array.isArray(widgetsData) && widgetsData.length && canRestoreScroll && pageRenderCount > 0) {
			const scrollPositionY = scrollRestorationStore.getPageScrollPosition(pathname, pageCount),
				timeout = window.setTimeout(() => {
					
					const document = window.document || {},
						body = document.body || {},
						bodyClientHeight = body.clientHeight || 0;

					console.log('**&&**', scrollPositionY <= bodyClientHeight, bodyClientHeight, scrollPositionY);
					
					if (scrollPositionY > 100 && scrollPositionY <= bodyClientHeight) {
						setCanRestoreScroll(false);
						console.log('**&&** s');
						window.scrollTo({ top: scrollPositionY});
					}
				}, 300);

			return () => {
				window.clearTimeout(timeout);
			};
		}
		// 
	}, [widgetsData]);

	return <></>;
}

export default withRouter(ScrollRestoration);