// beaconUtils.js

import { debugLog } from "./logUtils";
import sessionManager from "./sessionManager"

// Constants
const TIME_STAMP_MACRO = '[TIME_STAMP]',
	SESSION_ID_MACRO = '[SESSION_ID]';

export const parsePlayerBeacons = (beaconsData = {}) => {
	const playerBeacons = beaconsData.player || [];

	return Array.isArray(playerBeacons) ? playerBeacons : [];
}

/**
	* Processes and sends an array of beacon requests using invisible images.
	* @param {Array} beacons - The tracking endpoint.
	* @return {null}
*/
export const processBeacons = (beacons = []) => {
	beacons.forEach((beacon) => {
		debugLog('Beacons = ', beacon);
		sendBeacon(beacon);
	});
	return null;
};

/**
	* Sends a beacon request using an invisible image.
	* @param {string} beacon - The tracking endpoint.
	* @return {null}
*/
export const sendBeacon = (beacon = '') => {
	if (beacon) {
		const sessionID = sessionManager.getSessionId(),
			parsedBeacon = beacon.replace(TIME_STAMP_MACRO, Date.now()).replace(SESSION_ID_MACRO, sessionID),
			img = new Image();

			img.src = parsedBeacon;
			img.style.display = "none"; // Keep it invisible
			document.body.appendChild(img);
	}
	return null;
};

