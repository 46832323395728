import React, { useEffect } from 'react';

const PreventRightClick = () => {

    useEffect(() => {
        if(window.document){
            //mouse right click 
            document.addEventListener('contextmenu', event => event.preventDefault());
        }
    },[])

  return (
    <></>
  );
}

export default PreventRightClick;