import React, { useEffect, useState } from 'react';
import { generateRandomID, setL } from '../../../util/lib';
import { debugLog } from '../../../util/logUtils';

export const TRAILER = 'trailer';
export const FULLSCREEN_FORCED = 'fullscreen-forced';

const VideoCardWrapper = (VideoCard) => {
	const VideoCardWrapperComp = (props = {}) => {
		const compData = props.data || {},
			vidData = compData.data || {},
			trailerData = vidData.trailer || {},
			primaryTrailerData = trailerData.primary || {},
			location = window.location || {},
			pathname = location.pathname || '',
			videoId = vidData.video_id || '0',
			videoKey = vidData.video_key || vidData.key || '',
			trailerVideoKey = primaryTrailerData.video_key || trailerData.video_key,
			[ id ] = useState('vtn-vd-' + generateRandomID(6)),
			[ isPlayerSet, setIsPlayerSet] = useState(false);

		useEffect(() => {			
			if (pathname !== '/login' &&
				pathname !== '/login/' &&
				pathname !== '/register' &&
				pathname !== '/register/' &&
				pathname !== '/plans' &&
				pathname !== '/payment' &&
				pathname !== '/payment/' &&
				pathname !== '/plans/' &&
				pathname !== '/ssoAuth' &&
				!pathname.includes('/plans/')) {
				setL('lastVideoLocation', pathname);
			} else if (pathname.includes('/plans/') && pathname !== '/plans/') {
				setL('lastVideoLocation', '/');
			}
			//private plans redirection 
			//The key "postLoginRedirection" is only for pages that are not content(video, show, etc) use 
			//last video location for content redirection
			if(pathname.match(/\/plans\/\w+/g)) {
				setL('postLoginRedirectUrl', pathname)
			}

		}, [pathname]);

		

		return (<VideoCard {...{ ...props, id }} initVideoPlayer={(targetID = '', pageTakeOver = false, resume = false, forceBegin = false, videoType = '', videoKeyL = '', exitOnComplete = true, setVideoDetailsFallback = '', startMuted = false, playerSetOverride = false ) => {
			console.log('sweeet', isPlayerSet);
			if (!isPlayerSet || pageTakeOver || playerSetOverride ) {
				let parsedVideoKey;

				const { data = {}, setVideoDetails = setVideoDetailsFallback } = props,
					setVideoDetailsL = setVideoDetails || '',
					{ data: cardData = {}, meta: cardMeta = {} } = data,
					canPlayVideo = cardMeta.canPlayVideoYN || false;
					
				if (videoType === FULLSCREEN_FORCED && videoKeyL) {
					parsedVideoKey = videoKeyL;
				} else {
					parsedVideoKey = videoType === TRAILER ? trailerVideoKey : (canPlayVideo ? videoKey : '');
				}

				console.log('sweeet sweeet sweeet sweeet');
				
				if (setVideoDetailsL) {
					setVideoDetailsL({
						video_key: parsedVideoKey,
						video_id: videoId || '',
						video_targetid: canPlayVideo ? targetID : '',
						video_pageTakeOver: pageTakeOver,
						video_resume: resume,
						video_forceBegin: forceBegin,
						exitOnComplete,
						video_limitedViewing: cardMeta.isRestrictedYN ? cardData.restrictedViewing || {} : {},
						startMuted
					});

					setIsPlayerSet(true);
				};
			}
		}}>
		</VideoCard>);
	};

	return VideoCardWrapperComp;
};

export default VideoCardWrapper;