import React from 'react';
import CommentBox from '../videoComments/CommentBox';
import { getUserDetails } from '../../../util/lib';

import './scss/CommentBoxWrapper.scss';
import Close from '../../svg/IconV2-2/Close';

const CommentBoxWrapper = ({ data = {}, id = 'comment-box', onClose = '' } = {}) => {
	const userDetails = getUserDetails(),
		{ videoID = '0', ...rest } = data;


	return <div id={id} className="comment-box-wrapper" >
		<div className='top-box-pannel'>
			<div className='box-title'>
				Live Chat
				<div className='red-dot'></div>
			</div>
			<div className='close-btn' onClick={(event = '') => {
				if (onClose) {
					onClose(event)
				}
			}}>
				<Close />
			</div>
		</div>
		<div className='box-inner'>
			<CommentBox data={rest} userDetails={userDetails} videoID={videoID} fullScreen={true}/>
		</div>
	</div>
};

export default CommentBoxWrapper;